@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
@layer base {
  html {
    @apply scroll-smooth;
  }
}

/* Gilroy Font */
@font-face {
  font-family: "Gilroy-Black";
  src: url("./fonts/gilroy-font/Gilroy-Black.ttf");
}

@font-face {
  font-family: "Gilroy-Thin";
  src: url("./fonts/gilroy-font/Gilroy-Thin.ttf");
}

@font-face {
  font-family: "Gilroy-UltraLight";
  src: url("./fonts/gilroy-font/Gilroy-UltraLight.ttf");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("./fonts/gilroy-font/Gilroy-Light.ttf");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("./fonts/gilroy-font/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/gilroy-font/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("./fonts/gilroy-font/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./fonts/gilroy-font/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("./fonts/gilroy-font/Gilroy-ExtraBold.ttf");
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: url("./fonts/gilroy-font/Gilroy-Heavy.ttf");
}

/* ProximaNova Font */
@font-face {
  font-family: "ProximaNova-Black";
  src: url("./fonts/Proxima/ProximaNova-Black.otf");
}

@font-face {
  font-family: "ProximaNova-Thin";
  src: url("./fonts/Proxima/ProximaNova-Thin.otf");
}

@font-face {
  font-family: "ProximaNova-UltraLight";
  src: url("./fonts/Proxima/ProximaNova-LightIt.otf");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("./fonts/Proxima/ProximaNova-Light.otf");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("./fonts/Proxima/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "ProximaNova-SemiBold";
  src: url("./fonts/Proxima/ProximaNova-Semibold.otf");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("./fonts/Proxima/ProximaNova-Bold.otf");
}

@font-face {
  font-family: "ProximaNova-ExtraBold";
  src: url("./fonts/Proxima/ProximaNova-Extrabld.otf");
}

.active {
  background-color: black;
}
